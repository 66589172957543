import { useAuthContext } from "./auth";
import Spinner from "@awsui/components-react/spinner";
import { useEffect, useState } from "react";
import {
  S3Client,
  ListObjectsV2Command,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import WrapCentered from "./WrapCentered";
import Link from "@awsui/components-react/link";
import FormField from "@awsui/components-react/form-field";
import Input from "@awsui/components-react/input";
import useSWR from "swr";

const S3List = () => {
  const auth = useAuthContext();
  const [client, setClient] = useState<S3Client>();
  useEffect(() => {
    setClient(
      auth.tempCredentials
        ? new S3Client({
            credentials: auth.tempCredentials,
            region: process.env.REACT_APP_REGION,
          })
        : undefined
    );
  }, [auth.tempCredentials]);
  const s3SwrCacheKey =
    client && auth.tempCredentials
      ? JSON.stringify(auth.tempCredentials)
      : null;
  const { data, error } = useSWR(s3SwrCacheKey, () =>
    client
      ?.send(
        new ListObjectsV2Command({
          Bucket: process.env.REACT_APP_S3_BUCKET,
        })
      )
      .then((res) => {
        const keys =
          (res.Contents?.map((obj) => obj.Key)
            .filter(Boolean)
            .filter((obj) => !obj?.endsWith("/")) as string[]) ?? [];
        return Promise.all(
          keys.map(
            async (key) =>
              [
                key,
                await getSignedUrl(
                  client,
                  new GetObjectCommand({
                    Bucket: process.env.REACT_APP_S3_BUCKET,
                    Key: key,
                  }),
                  { expiresIn: 3600 }
                ),
              ] as const
          )
        );
      })
  );
  if (!auth.tempCredentials) {
    return (
      <WrapCentered>
        <div style={{ textAlign: "center" }}>
          Please acquire temporary credentials first
        </div>
      </WrapCentered>
    );
  }
  if (!data && !error) {
    return (
      <WrapCentered>
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Spinner />
          <div>Fetching S3 object list</div>
        </div>
      </WrapCentered>
    );
  }
  if (error) {
    return (
      <WrapCentered>
        <div style={{ textAlign: "center" }}>
          Something went wrong while listing the S3 bucket: {`${error}`}
        </div>
      </WrapCentered>
    );
  }
  return (
    <WrapCentered>
      <FormField label={"Your assumed role:"} className={"w-100"}>
        {/* TODO: implement  */}
        <Input
          value={auth.assumedRoleArn.split(":")[5]?.split("/")[1]}
          disabled
        />
      </FormField>
      <div style={{ width: "100%", display: "flex", gap: "2rem" }}>
        <FormField label={"Your Cognito group:"} className={""}>
          <Input
            value={auth.idToken?.decodePayload()["cognito:groups"]}
            disabled
          />
        </FormField>
        <FormField label={"Your principal tag:"} className={"w"}>
          <Input value={auth.idToken?.decodePayload().principal_tag} disabled />
        </FormField>
      </div>
      <div style={{ marginTop: "1rem" }}>
        These files are accessible only to the default authenticated role:
      </div>
      {data!
        .filter(([key]) => key.startsWith("general"))
        .map(([key, signedUrl]) => (
          <div key={key} style={{ marginLeft: "1rem" }}>
            <Link href={signedUrl} target="_blank">
              {key}
            </Link>
          </div>
        ))}
      <div>
        {" "}
        These files are accessible only if you're in the corresponding Cognito
        User Group (RBAC):
      </div>
      {data!
        .filter(
          ([key]) => key.startsWith("sales") || key.startsWith("accounting")
        )
        .map(([key, signedUrl]) => (
          <div key={key} style={{ marginLeft: "1rem" }}>
            <Link href={signedUrl} target="_blank">
              {key}
            </Link>
          </div>
        ))}
      <div>
        {" "}
        These files are accessible only if you have the right Principal Tag
        (ABAC):
      </div>
      {data!
        .filter(([key]) => key.startsWith("project-teams"))
        .map(([key, signedUrl]) => (
          <div key={key} style={{ marginLeft: "1rem" }}>
            <Link href={signedUrl} target="_blank">
              {key}
            </Link>
          </div>
        ))}
    </WrapCentered>
  );
};

export default S3List;
