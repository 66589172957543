import { useAuthContext } from "./auth";
import FormField from "@awsui/components-react/form-field";
import Input from "@awsui/components-react/input";
import OneMoment from "./OneMoment";
import Textarea from "@awsui/components-react/textarea";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Button from "@awsui/components-react/button";
import WrapCentered from "./WrapCentered";

function TemporaryCredentialsViewRaw() {
  const auth = useAuthContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth.fetchingTempCredentials && !auth.tempCredentials) {
      navigate("/temp-credentials");
    }
  }, [auth, navigate]);
  if (auth.fetchingTempCredentials) {
    return <OneMoment />;
  }

  function copyToClipboard() {
    if (!auth.tempCredentials) return;
    const text = [
      `export AWS_ACCESS_KEY_ID='${auth.tempCredentials.accessKeyId}'`,
      `export AWS_SECRET_ACCESS_KEY='${auth.tempCredentials.secretAccessKey}'`,
      `export AWS_SESSION_TOKEN='${auth.tempCredentials.sessionToken}'`,
    ].join("\n");
    navigator.clipboard.writeText(text);
  }

  return (
    <WrapCentered>
      {!!auth.tempCredentials && (
        <div
          style={{
            flexGrow: 1,
            minHeight: "0px",
            maxHeight: "100%",
            overflow: "scroll",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <FormField label={"Access Key Id"} className={"w-95"}>
            <Input value={auth.tempCredentials.accessKeyId} disabled />
          </FormField>
          <FormField label={"Secret Access Key"} className={"w-95"}>
            <Input value={auth.tempCredentials.secretAccessKey} disabled />
          </FormField>
          <FormField label={"Session Token"} className={"w-95"}>
            <Textarea
              value={auth.tempCredentials.sessionToken}
              disabled
              rows={16}
            />
          </FormField>
          <div style={{ flexGrow: "1" }}></div>
          <Button
            onClick={() => copyToClipboard()}
            className="w-95"
            disabled={!auth.tempCredentials}
          >
            <div style={{ textAlign: "center" }}>Copy to clipboard</div>
          </Button>
        </div>
      )}
    </WrapCentered>
  );
}

export default TemporaryCredentialsViewRaw;
