import { useAuthContext } from "./auth";
import Button from "@awsui/components-react/button";
import { useNavigate } from "react-router-dom";
import Spinner from "@awsui/components-react/spinner";
import WrapCentered from "./WrapCentered";

function TemporaryCredentialsGet() {
  const auth = useAuthContext();
  const navigate = useNavigate();
  return (
    <WrapCentered>
      <div>Select demo flavor:</div>
      <Button
        disabled={!auth.idToken || auth.fetchingTempCredentials}
        variant="primary"
        onClick={() => {
          auth.requestTempCredentials("DEFAULT");
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ textAlign: "center" }}>
            Use default authenticated role
          </div>
        </div>
      </Button>
      <Button
        variant="primary"
        disabled={!!auth.idToken || auth.fetchingTempCredentials}
        onClick={() => {
          auth.requestTempCredentials("DEFAULT");
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ textAlign: "center" }}>
            Use default unauthenticated role
          </div>
        </div>
      </Button>
      <Button
        disabled={!auth.idToken || auth.fetchingTempCredentials}
        variant="primary"
        onClick={() => {
          auth.requestTempCredentials("RBAC");
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ textAlign: "center" }}>
            Role Based Access Control (RBAC)
          </div>
        </div>
      </Button>
      <Button
        disabled={!auth.idToken || auth.fetchingTempCredentials}
        variant="primary"
        onClick={() => {
          auth.requestTempCredentials("ABAC");
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ textAlign: "center" }}>
            Attribute Based Access Control (ABAC)
          </div>
        </div>
      </Button>
      {(!!auth.tempCredentials ||
        !!auth.fetchingTempCredentials ||
        !!auth.tempCredentialsError) && (
        <div
          style={{
            width: "calc(100% - 8rem)",
            position: "absolute",
            bottom: "4rem",
          }}
        >
          {!!auth.tempCredentialsError && (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                gap: "1rem",
                margin: "1rem",
              }}
            >
              <div>
                Error while acquiring temporary credentials:{" "}
                {`${auth.tempCredentialsError}`}
              </div>
            </div>
          )}
          {!!auth.fetchingTempCredentials && (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                gap: "1rem",
                margin: "1rem",
                alignItems: "center",
              }}
            >
              <Spinner />
              <div>Acquiring temporary credentials</div>
            </div>
          )}
          {!!auth.tempCredentials && !auth.fetchingTempCredentials && (
            <>
              <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                Successfully acquired temporary credentials
              </div>
              <Button
                onClick={() => {
                  navigate("view");
                }}
                className="w-100"
                disabled={auth.fetchingTempCredentials}
              >
                <div style={{ textAlign: "center" }}>
                  View temporary credentials
                </div>
              </Button>
            </>
          )}
        </div>
      )}
    </WrapCentered>
  );
}

export default TemporaryCredentialsGet;
