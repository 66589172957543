import { useAuthContext } from "./auth";
import FormField from "@awsui/components-react/form-field";
import Input from "@awsui/components-react/input";
import OneMoment from "./OneMoment";
import Button from "@awsui/components-react/button";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import WrapCentered from "./WrapCentered";

function TemporaryCredentialsView() {
  const auth = useAuthContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth.fetchingTempCredentials && !auth.tempCredentials) {
      navigate("/temp-credentials");
    }
  }, [auth, navigate]);
  if (auth.fetchingTempCredentials) {
    return <OneMoment />;
  }
  return (
    <WrapCentered
    >
      {auth.tempCredentials && (
        <>
          <div>Success!</div>
          <div>
            With your ID token you've acquired temporary AWS credentials
          </div>
          <FormField label={"Role Name"} className={"w-100"}>
            {/* TODO: implement  */}
            <Input
              value={auth.assumedRoleArn.split(":")[5]?.split("/")[1]}
              disabled
            />
          </FormField>
          <FormField label={"Assume Role Arn"} className={"w-100"}>
            <Input value={auth.assumedRoleArn} disabled />
          </FormField>
          <FormField label={"Expires (minutes)"} className={"w-100"}>
            <Input
              value={
                expiryInMinutes(auth.tempCredentials.expiration) ?? "unknown"
              }
              disabled
            />
          </FormField>
          <FormField label={"Identity Id"} className={"w-100"}>
            <Input value={auth.tempCredentials.identityId} disabled />
          </FormField>
          <Button variant="primary" onClick={() => navigate("raw")}>
            <div style={{ textAlign: "center" }}>View raw credentials</div>
          </Button>
        </>
      )}
    </WrapCentered>
  );
}

export default TemporaryCredentialsView;

function expiryInMinutes(d?: Date) {
  if (!d) return d;
  const timeDiff = (d.valueOf() - Date.now()) / (1000 * 60);
  return Math.floor(timeDiff).toString();
}
