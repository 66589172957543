import Button from "@awsui/components-react/button";
import { useNavigate } from "react-router-dom";

const WrapCentered = (props: {
  children: React.ReactNode;
  hideBackButton?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "2rem",
        padding: "4rem",
        boxSizing: "border-box",
        position: "relative",
        paddingTop: "6rem",
      }}
    >
      {!props.hideBackButton && (
        <div style={{ position: "absolute", top: "1rem", left: "0rem" }}>
          <Button variant="link" onClick={() => navigate(-1)}>
            &lt; Back
          </Button>
        </div>
      )}
      {props.children}
    </div>
  );
};

export default WrapCentered;
