import { useAuthContext } from "./auth";
import WrapCentered from "./WrapCentered";
import Button from "@awsui/components-react/button";

const IdTokenViewRaw = () => {
  const auth = useAuthContext();
  const idToken = auth.idToken?.getJwtToken();
  return (
    <WrapCentered>
      {auth.idToken && (
        <textarea
          style={{ width: "100%", height: "100%", wordWrap: "break-word" }}
          disabled
          value={idToken}
        ></textarea>
      )}
      <Button
        onClick={() => idToken && navigator.clipboard.writeText(idToken)}
        className="w-100"
      >
        <div style={{ textAlign: "center" }}>Copy to clipboard</div>
      </Button>
    </WrapCentered>
  );
};

export default IdTokenViewRaw;
