import WrapCentered from "./WrapCentered";
import Spinner from "@awsui/components-react/spinner";

const OneMoment = (props: { hideBackButton?: boolean }) => {
  return (
    <WrapCentered hideBackButton={props.hideBackButton}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          justifyContent: "center",
        }}
      >
        <Spinner />
        <div>One moment please ...</div>
      </div>
    </WrapCentered>
  );
};

export default OneMoment;
