import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@awsui/global-styles/index.css";
import App from "./App";
import SignIn from "./SignIn";
import Main from "./Main";
import IdTokenView from "./IdTokenView";
import IdTokenViewRaw from "./IdTokenViewRaw";
import TemporaryCredentialsGet from "./TemporaryCredentialsGet";
import TemporaryCredentialsView from "./TemporaryCredentialsView";
import TemporaryCredentialsViewRaw from "./TemporaryCredentialsViewRaw";
import UserAttributes from "./UserAttributes";
import S3List from "./S3List";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "@aws-amplify/core";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthContextProvider } from "./auth";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID_DEFAULT,
    mandatorySignIn: false,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <div style={{ width: "375px", height: "667px", margin: "auto" }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}></Route>
            <Route path="/sign-in" element={<SignIn />}></Route>
            <Route path="/main" element={<Main />}></Route>
            <Route path="/id-token/view" element={<IdTokenView />}></Route>
            <Route
              path="/id-token/view/raw"
              element={<IdTokenViewRaw />}
            ></Route>
            <Route
              path="/temp-credentials"
              element={<TemporaryCredentialsGet />}
            ></Route>
            <Route
              path="/temp-credentials/view"
              element={<TemporaryCredentialsView />}
            ></Route>
            <Route
              path="/temp-credentials/view/raw"
              element={<TemporaryCredentialsViewRaw />}
            ></Route>
            <Route path="/attributes" element={<UserAttributes />}></Route>
            <Route path="/s3-list" element={<S3List />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
