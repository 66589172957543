import { useAuthContext } from "./auth";
import FormField from "@awsui/components-react/form-field";
import Input from "@awsui/components-react/input";
import Spinner from "@awsui/components-react/spinner";
import ButtonDropDown from "@awsui/components-react/button-dropdown";
import WrapCentered from "./WrapCentered";

const availableUserGroups = [
  { id: "accounting", text: "Set to: accounting" },
  { id: "sales", text: "Set to: sales" },
  { id: "none", text: "Remove" },
] as const;

const availablePrincipalTags = [
  { id: "team-a", text: "Set to: team-a" },
  { id: "team-b", text: "Set to: team-b" },
  { id: "none", text: "Remove" },
] as const;

const UserAttributes = () => {
  const auth = useAuthContext();
  const {
    "cognito:groups": currentUserGroups,
    principal_tag: currentPrincipalTag,
  } = auth.idToken?.decodePayload() ?? {};
  const currentUserGroup = currentUserGroups?.pop();

  return (
    <WrapCentered>
      <div>
        These attributes are present in your ID token and determine your
        permissions. For the purpose of this demo, you can change them yourself.
      </div>
      <div>NOTE: Normally users must NOT be able to do this themselves!</div>
      <FormField
        label={"Cognito User Group"}
        className={"w-100"}
        description="Your Cognito User Group determines the role you can assume into,
        in the Role Based Access Control (RBAC) scenario:"
      >
        <div style={{ display: "flex", gap: "1rem" }}>
          <div style={{ flexGrow: "1" }}>
            <Input value={currentUserGroup} disabled />
          </div>
          <ButtonDropDown
            variant="primary"
            disabled={auth.updatingUserAttributes}
            items={availableUserGroups.map((item) => ({
              ...item,
              disabled: item.id === currentUserGroup,
            }))}
            onItemClick={(e) => {
              auth.requestUpdateUserAttributes({
                "custom:req_cognito_group":
                  e.detail.id === "none" ? "" : e.detail.id,
              });
            }}
          >
            Change
          </ButtonDropDown>
        </div>
      </FormField>
      <FormField
        label={"Principal Tag"}
        className={"w-100"}
        description="Your Principal Tag determines your permissions,
       in the Attribute Based Access Control (ABAC) scenario:"
      >
        <div style={{ display: "flex", gap: "1rem" }}>
          <div style={{ flexGrow: "1" }}>
            <Input value={currentPrincipalTag} disabled />
          </div>
          <ButtonDropDown
            variant="primary"
            disabled={auth.updatingUserAttributes}
            items={availablePrincipalTags.map((item) => ({
              ...item,
              disabled: item.id === currentPrincipalTag,
            }))}
            onItemClick={(e) => {
              auth.requestUpdateUserAttributes({
                "custom:req_principal_tag":
                  e.detail.id === "none" ? "" : e.detail.id,
              });
            }}
          >
            Change
          </ButtonDropDown>
        </div>
      </FormField>
      <div
        style={{
          width: "calc(100% - 8rem)",
          position: "absolute",
          bottom: "4rem",
        }}
      >
        {!!auth.updatingUserAttributes && (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              gap: "1rem",
              margin: "1rem",
            }}
          >
            <Spinner />
            <div>Updating your user attributes</div>
          </div>
        )}
        {auth.updateAttributesError && (
          <div>{`${auth.updateAttributesError}`}</div>
        )}
      </div>
    </WrapCentered>
  );
};

export default UserAttributes;
