import { useAuthContext } from "./auth";
import FormField from "@awsui/components-react/form-field";
import Input from "@awsui/components-react/input";
import Button from "@awsui/components-react/button";
import { useNavigate } from "react-router-dom";
import WrapCentered from "./WrapCentered";

const IdTokenView = () => {
  const auth = useAuthContext();
  const navigate = useNavigate();
  return (
    <WrapCentered>
      <div>These are the claims in your ID token</div>
      <div
        style={{
          flexGrow: 1,
          width: "100%",
          minHeight: "0px",
          overflow: "scroll",
          gap: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {auth.idToken &&
          Object.entries(auth.idToken.decodePayload()).map(([key, value]) => (
            <FormField label={key} key={key} className={"w-95"}>
              <Input value={value} disabled />
            </FormField>
          ))}
      </div>
      <Button
        className="w-100"
        variant="primary"
        onClick={() => navigate("raw")}
      >
        <div style={{ textAlign: "center" }}>View raw token</div>
      </Button>
    </WrapCentered>
  );
};

export default IdTokenView;
