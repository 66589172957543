import Button from "@awsui/components-react/button";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "./auth";
import OneMoment from "./OneMoment";

function App() {
  const navigate = useNavigate();
  const auth = useAuthContext();
  useEffect(() => {
    if (auth.idToken) {
      navigate("/main");
    }
  }, [auth, navigate]);
  if (auth.checkingAuthStatus) {
    return <OneMoment />;
  }
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          width: "100%",
          fontSize: "4rem",
          fontWeight: "bolder",
          top: "15%",
          left: "0",
          padding: "2rem",
          boxSizing: "border-box",
        }}
      >
        IAM334 DEMO
      </div>
      <div
        style={{
          gap: "2rem",
          padding: "4rem",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Button variant="primary" onClick={() => navigate("sign-in")}>
          <div style={{ textAlign: "center" }}>Sign up or Sign in</div>
        </Button>
        <Button onClick={() => navigate("main")}>
          <div style={{ textAlign: "center" }}>Proceed anonymous</div>
        </Button>
      </div>
    </div>
  );
}

export default App;
