import { useState, useEffect } from "react";
import { useAuthContext } from "./auth";
import Button from "@awsui/components-react/button";
import Input from "@awsui/components-react/input";
import { useNavigate } from "react-router-dom";
import OneMomentPlease from "./OneMoment";
import Spinner from "@awsui/components-react/spinner";
import WrapCentered from "./WrapCentered";
import FormField from "@awsui/components-react/form-field";

function SignIn() {
  const navigate = useNavigate();
  const auth = useAuthContext();
  useEffect(() => {
    if (auth.idToken) {
      return navigate("/main");
    }
  }, [auth.idToken, navigate]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [email, setEmail] = useState("");
  const isEmailValid = !!email && !!email.match(/@.+\..+/);
  const signIn = async () => {
    setShowSpinner(true);
    try {
      await auth.signIn(email);
    } finally {
      setShowSpinner(false);
    }
  };
  const signUpAndIn = async () => {
    setShowSpinner(true);
    try {
      await auth.signUp({ email });
      await auth.signIn(email)?.signInPromise;
    } finally {
      setShowSpinner(false);
    }
  };
  if (auth.checkingAuthStatus) {
    return <OneMomentPlease hideBackButton={true} />;
  }
  if (auth.signInError && auth.signInError.name === "UserNotFoundException") {
    return (
      <WrapCentered hideBackButton={true}>
        <div>
          Looks like you don't have an account yet. No problem, we'll create one
          for you:
        </div>
        <div style={{ textAlign: "center" }}>{email}</div>
        <Button onClick={signUpAndIn} variant="primary" disabled={showSpinner}>
          <div style={{ display: "flex" }}>
            <div style={{ textAlign: "center", flexGrow: "1" }}>Continue</div>
            {showSpinner && <Spinner />}
          </div>
        </Button>
        <Button onClick={auth.clearSignInError} disabled={showSpinner}>
          <div style={{ textAlign: "center", flexGrow: "1" }}>Cancel</div>
        </Button>
      </WrapCentered>
    );
  }
  if (!auth.signingIn) {
    const signInMessage = auth.secretHashInvalid ? (
      <div>
        Your secret sign-in link has expired. Enter your e-mail address to
        request a new secret sign-in link:
      </div>
    ) : auth.signInError ? (
      <div>
        There was an error signing you in: {`${auth.signInError}`}. Please try
        again:
      </div>
    ) : (
      <div>Enter your e-mail address to get started:</div>
    );
    return (
      <WrapCentered>
        {signInMessage}
        <FormField>
          <Input
            value={email}
            onChange={(e) => setEmail(e.detail.value)}
            disabled={showSpinner || auth.signingIn}
            onKeyUp={(e) => e.detail.key === "Enter" && signIn()}
            type="email"
          ></Input>
        </FormField>
        <Button
          onClick={signIn}
          disabled={!email || !isEmailValid || showSpinner}
          variant="primary"
        >
          <div style={{ display: "flex" }}>
            <div style={{ textAlign: "center", flexGrow: "1" }}>
              {auth.secretHashInvalid
                ? "Request new sign-in link"
                : "Sign up or Sign in"}
            </div>
            {showSpinner && <Spinner />}
          </div>
        </Button>
      </WrapCentered>
    );
  }
  if (
    auth.signInChallenge === "PROVIDE_SECRET_HASH" &&
    !auth.checkingSecrethash
  ) {
    return (
      <WrapCentered>
        <div style={{ textAlign: "center" }}>
          We've emailed you a secret sign-in link
        </div>
      </WrapCentered>
    );
  }
  return <OneMomentPlease />;
}

export default SignIn;
