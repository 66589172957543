import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from "react";
import { Hub } from "@aws-amplify/core";
import { Auth, CognitoUser as CognitoUser_ } from "@aws-amplify/auth";
import { STSClient, GetCallerIdentityCommand } from "@aws-sdk/client-sts";

type SignInChallenge = "PROVIDE_AUTH_PARAMETERS" | "PROVIDE_SECRET_HASH";

type TemporaryCredentialsType = "DEFAULT" | "RBAC" | "ABAC";

type CognitoUser = CognitoUser_ & {
  challengeParam?: {
    challenge: SignInChallenge;
  };
};

const AuthContext = createContext<ReturnType<typeof useAuth>>((() => {
  throw new Error("No default context");
}) as any);

export const AuthContextProvider = (props: { children: React.ReactNode }) => {
  const auth = useAuth();
  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const auth = useContext(AuthContext);
  return auth;
};

const useAuth = () => {
  const [cognitoUser, setCognitoUser_] = useState<CognitoUser>();
  const idToken = cognitoUser?.getSignInUserSession()?.getIdToken();
  const isAuthenticated = !!idToken;
  const setCognitoUser = (user?: CognitoUser) => {
    setCognitoUser_(user);
    setSignInChallenge(user?.challengeParam?.challenge);
  };
  const [signInError, setSignInError] = useState<Error>();
  const [checkingAuthStatus, setCheckingAuthStatus] = useState(true);
  const [signInChallenge, setSignInChallenge] = useState<SignInChallenge>();
  const [secretHash, setSecretHash] = useState(window.location.hash.slice(1));
  const removeSecretHash = () => {
    window.history.pushState(
      "",
      document.title,
      window.location.pathname + window.location.search
    );
    setSecretHash("");
  };

  const [invalidSecretHash, setInvalidSecretHash] = useState("");
  const [signingIn, setSigningIn] = useState(false);
  const [signingOut, setSigningOut] = useState(false);
  const [tempCredentialsType, setTempCredentialsType] =
    useState<TemporaryCredentialsType>("DEFAULT");
  const [tempCredentialsRequest, setTempCredentialsRequest] = useState(0);
  const requestTempCredentials = (tempCredsType: TemporaryCredentialsType) => {
    setTempCredentialsType(tempCredsType);
    setTempCredentialsRequest((state) => state + 1);
  };
  const [tempCredentials, setTempCredentials] =
    useState<Awaited<ReturnType<typeof Auth["currentCredentials"]>>>();
  const [assumedRoleArn, setAssumedRoleArn] = useState("");
  const [fetchingTempCredentials, setFetchingTempCredentials] = useState(false);
  const [tempCredentialsError, setTempCredentialsError] = useState<Error>();

  const [requestedUserAttributeUpdate, setRequestedUserAttributeUpdate] =
    useState<{ [key: string]: any }>();
  const [updateUserAttributesRequest, setUpdateUserAttributesRequest] =
    useState(0);
  const requestUpdateUserAttributes = (update: { [key: string]: any }) => {
    setRequestedUserAttributeUpdate(update);
    setUpdateUserAttributesRequest((state) => state + 1);
  };
  const [updatingUserAttributes, setUpdatingUserAttributes] = useState(false);
  const [updateAttributesError, setUpdateAttributesError] = useState<Error>();

  // Log Auth status for debugging
  const authStatus = JSON.stringify({
    isAuthenticated,
    secretHash,
    invalidSecretHash,
    cognitoUser,
    checkingAuthStatus,
    signingIn,
    signingOut,
  });
  useEffect(() => {
    console.log("Auth status:", JSON.parse(authStatus));
  }, [authStatus]);

  // Get Role Arn for temp credentials
  useEffect(() => {
    if (!tempCredentials) return;
    let cancelled = false;
    const stsClient = new STSClient({
      region: process.env.REACT_APP_REGION,
      credentials: tempCredentials,
    });
    stsClient.send(new GetCallerIdentityCommand({})).then((res) => {
      if (cancelled) return;
      if (!res.Arn) return;
      setAssumedRoleArn(res.Arn);
    });
    return () => {
      cancelled = true;
    };
  }, [tempCredentials]);

  // Update user attributes on request
  useEffect(() => {
    let cancelled = false;
    if (!updateUserAttributesRequest) return;
    if (!requestedUserAttributeUpdate) return;
    setUpdatingUserAttributes(true);
    setUpdateAttributesError(undefined);
    Auth.currentUserPoolUser()
      .then((user) => {
        return Auth.updateUserAttributes(user, requestedUserAttributeUpdate);
      })
      .catch((err) => {
        if (cancelled) return;
        setUpdateAttributesError(err);
      })
      .finally(() => {
        if (cancelled) return;
        setUpdatingUserAttributes(false);
      });
    return () => {
      cancelled = true;
    };
  }, [updateUserAttributesRequest, requestedUserAttributeUpdate]);

  // Request temporary credentials
  useEffect(() => {
    let cancelled = false;
    if (!tempCredentialsRequest) return;
    if (!tempCredentialsType) return;
    setFetchingTempCredentials(true);
    setTempCredentialsError(undefined);
    setTempCredentials(undefined);
    const currentConfig = Auth.configure();
    const identityPoolId = {
      DEFAULT: process.env.REACT_APP_IDENTITY_POOL_ID_DEFAULT,
      RBAC: process.env.REACT_APP_IDENTITY_POOL_ID_RBAC,
      ABAC: process.env.REACT_APP_IDENTITY_POOL_ID_ABAC,
    }[tempCredentialsType];
    Auth.configure({
      ...currentConfig,
      identityPoolId,
    });
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .catch(() => {})
      .then(() => Auth.currentCredentials())
      .then((creds) => {
        if (cancelled) return;
        if (creds instanceof Error) {
          // Human readable error message
          creds.message =
            tempCredentialsType === "RBAC"
              ? "User is not part of a user group"
              : creds.message;
          setTempCredentialsError(creds);
          return;
        }
        setTempCredentials(creds);
      })
      .finally(() => {
        if (cancelled) return;
        setFetchingTempCredentials(false);
      });
    return () => {
      cancelled = true;
    };
  }, [tempCredentialsRequest, tempCredentialsType]);

  // Check Auth status initially and when receiving hub events for auth
  useEffect(() => {
    let cancelled = false;
    const checkAuthStatus = async () => {
      console.log("Checking auth status ...");
      setCheckingAuthStatus(true);
      let cognitoUser_: CognitoUser | undefined = undefined;
      try {
        cognitoUser_ = await Auth.currentAuthenticatedUser();
        if (cancelled) return;
      } catch {}
      if (cognitoUser_) setSigningIn(false);
      console.log(cognitoUser_ ? "Authenticated" : "Not authenticated");
      setCognitoUser(cognitoUser_);
      setCheckingAuthStatus(false);
      console.log("Done checking auth status");
    };
    checkAuthStatus();
    Hub.listen("auth", () => {
      console.log("Auth hub event!");
      checkAuthStatus();
    });
    return () => {
      cancelled = true;
    };
  }, []);

  // Sign out
  const signOut = () => {
    setSigningOut(true);
    setTempCredentials(undefined);
    return Auth.signOut().finally(() => setSigningOut(false));
  };

  // Sign in
  const signIn = useCallback(
    (userName: string) => {
      console.log("Starting sign-in for:", userName);
      if (signingIn || checkingAuthStatus || isAuthenticated) return;
      if (invalidSecretHash) removeSecretHash();
      let cancelled = false;
      let completed = false;
      const signInPromise = Auth.signIn(userName, undefined, {
        source: "signIn",
      })
        .then((user) => {
          if (!cancelled) {
            setSigningIn(true);
            setSignInError(undefined);
            setCognitoUser(user);
          }
        })
        .catch((err) => {
          if (!cancelled) {
            setSigningIn(false);
            setSignInError(err as Error);
          }
        })
        .finally(() => {
          completed = true;
        });
      return {
        signInPromise,
        cancel: () => {
          if (completed) return;
          console.log("Cancelling sign-in");
          cancelled = true;
        },
      };
    },
    [signingIn, checkingAuthStatus, invalidSecretHash, isAuthenticated]
  );

  // Sign up
  const signUp = async (props: { email: string; phoneNumber?: string }) => {
    const params = {
      username: props.email,
      password: getRandomString(30),
      attributes: {
        email: props.email,
        phone_number: props.phoneNumber,
      },
    };
    const { user } = await Auth.signUp(params);
    setCognitoUser(user as CognitoUser);
  };

  const provideAuthParameters = useCallback(async () => {
    console.log("usePriorSigninHash:", secretHash ? "yes" : "no");
    return Auth.sendCustomChallengeAnswer(cognitoUser, "__dummy__", {
      source: "sendCustomChallengeAnswer",
      redirectUri: window.location.href.split("#")[0],
      usePriorSigninHash: secretHash ? "yes" : "no",
    }).then(setCognitoUser);
  }, [cognitoUser, secretHash]);

  const provideSecretLoginHash = useCallback(
    async (hash: string) => {
      return Auth.sendCustomChallengeAnswer(cognitoUser, hash, {
        source: "sendCustomChallengeAnswer",
      }).then((cognitoUser_) => {
        setCognitoUser(cognitoUser_);
        removeSecretHash();
      });
    },
    [cognitoUser]
  );

  // React to sign-in challenges:
  useEffect(() => {
    if (!signInChallenge) return;
    if (checkingAuthStatus) return;
    if (isAuthenticated) return;
    setSigningIn(true);
    if (signInChallenge === "PROVIDE_AUTH_PARAMETERS") {
      console.log("Sign-in: providing auth parameters");
      provideAuthParameters().catch((err) => {
        setSigningIn(false);
        setSignInError(err);
      });
    } else if (signInChallenge === "PROVIDE_SECRET_HASH") {
      if (secretHash) {
        console.log("Sign-in: providing secret hash");
        provideSecretLoginHash(secretHash).catch((err) => {
          setSigningIn(false);
          setSignInError(err);
          setInvalidSecretHash(secretHash);
        });
      }
    }
  }, [
    signInChallenge,
    provideAuthParameters,
    provideSecretLoginHash,
    secretHash,
    checkingAuthStatus,
    isAuthenticated,
  ]);

  // Initiate sign-in, if we have a secret hash
  useEffect(() => {
    if (!secretHash) return;
    if (secretHash === invalidSecretHash) return;
    if (checkingAuthStatus) return;
    if (signingIn) return;
    if (isAuthenticated) return;

    const [paramsB64] = secretHash.split(".");
    let params: { [key: string]: unknown };
    try {
      params = JSON.parse(atob(paramsB64));
    } catch {
      console.log("Invalid secret hash");
      return;
    }
    if (!params.userName || typeof params.userName !== "string") {
      console.log("Invalid secret hash");
      return;
    }
    console.log("Initiating sign-in for secret hash");
    return signIn(params.userName)?.cancel;
  }, [
    secretHash,
    signIn,
    checkingAuthStatus,
    signingIn,
    invalidSecretHash,
    isAuthenticated,
  ]);

  return {
    isAuthenticated,
    idToken,
    signIn,
    signInChallenge,
    signInError,
    clearSignInError: () => setSignInError(undefined),
    signUp,
    signingIn,
    signOut,
    signingOut,
    checkingAuthStatus,
    tempCredentials,
    requestTempCredentials,
    fetchingTempCredentials,
    tempCredentialsError,
    requestUpdateUserAttributes,
    updatingUserAttributes,
    updateAttributesError,
    secretHashInvalid: !!invalidSecretHash,
    checkingSecrethash: !!secretHash && !invalidSecretHash,
    assumedRoleArn,
  };
};

function getRandomString(bytes: number) {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join("");
}

function intToHex(nr: number) {
  return nr.toString(16).padStart(2, "0");
}
