import { useAuthContext } from "./auth";
import Button from "@awsui/components-react/button";
import { useNavigate } from "react-router-dom";
import OneMoment from "./OneMoment";
import WrapCentered from "./WrapCentered";
import Spinner from "@awsui/components-react/spinner";

const Main = () => {
  const auth = useAuthContext();
  const navigate = useNavigate();
  const email = auth.idToken?.decodePayload().email;
  if (!auth.idToken && (auth.checkingAuthStatus || auth.signingIn)) {
    return <OneMoment />;
  }
  return (
    <WrapCentered hideBackButton={true}>
      <div>Welcome, {email ? email : "anonymous guest"}</div>
      {!!auth.idToken && (
        <Button onClick={() => navigate("/id-token/view")} variant="primary">
          <div style={{ textAlign: "center" }}>View your ID token</div>
        </Button>
      )}
      <Button onClick={() => navigate("/temp-credentials")} variant="primary">
        <div style={{ textAlign: "center" }}>Get temporary AWS credentials</div>
      </Button>
      {!!email && (
        <Button onClick={() => navigate("/attributes")} variant="primary">
          <div style={{ textAlign: "center" }}>
            Update my RBAC/ABAC permissions
          </div>
        </Button>
      )}
      <Button onClick={() => navigate("/s3-list")} variant="primary">
        <div style={{ textAlign: "center" }}>Access files on S3</div>
      </Button>
      {!!email && (
        <Button
          onClick={() => auth.signOut().finally(() => navigate("/"))}
          disabled={auth.signingOut}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>Sign out</div>
            {auth.signingOut && <Spinner />}
          </div>
        </Button>
      )}
      {!email && (
        <Button onClick={() => navigate("/sign-in")}>
          <div style={{ textAlign: "center" }}>Sign up or Sign in</div>
        </Button>
      )}
    </WrapCentered>
  );
};

export default Main;
